import React from "react"
import Columns from "../components/Columns/Columns"
import Layout from "../components/Layout/Layout"
import MainSection from "../components/MainSection/MainSection"
import Section from "../components/Section/Section"
import Seo from "../components/Seo/Seo"
import "../styles/career.scss"
import icoJigsaw from "../svg/Jigsaw.svg"
import icoID from "../svg/DetectAI.svg"
import icoImprove from "../svg/Improve.svg"
import careerValueImage from "../images/careerValueImage.png"
import careerImage from "../images/careerImage.png"
//@ts-ignore
import Fade from "react-reveal/Fade"
import Button from "../components/Button/Button"

const Career = () => {
  return (
    <Layout hideForm>
      <Seo
        title="Join Resistant AI."
        description="Find out what it's like to work for Resistant AI."
      />
      <MainSection
        caption="Careers"
        title="Join"
        titleHighlight=" Resistant AI"
        text="Do you have drive, a growth mindset, and a big heart? We'd like to hear from you."
        className="Career__main"
        // showPattern
        divided
        imageLink={careerImage}
        button={{
          link: "#demo",
          variant: "blue",
          text: "Contact Us",
          size: "large",
        }}
      ></MainSection>
      <Section gray className="Career__aboutUs">

        <div>
            <h2>Who <span className="highlighted">We Are</span></h2>
            <p>
              We protect AI-based financial automation systems from manipulation,
              misuse and intentional attacks with AI. We discover and prevent
              financial fraud based on forged documents, manipulated transactions
              and stolen or synthetic identities.
            </p>
        </div>

      </Section>

      <Section className="Career__values">

        <div className="w-wrap">

          <div className="w1-2 Career__values__bg">

          </div>

          <div className="w1-2">
            <h2 className="pt-10">Our <span className="highlighted">Values</span></h2>
              <div className="about__Columns">

                <div className="Columns__column">
                  <div>
                    <h3>Leaders in Machine Learning For Security</h3>
                    <p>
                      We’re committed to providing robust and innovative products in the AI
                      oversight market that are easy to integrate into existing processes.
                    </p>
                  </div>
                </div>

                <div className="Columns__column">
                  <div>
                    <h3>Built By Security Experts For Security Experts</h3>
                    <p>
                      Data Security is our key focus. We understand the
                      complexity of evolving risks and are constantly improving the security of our systems.
                    </p>
                  </div>
                </div>

                <div className="Columns__column">
                  <div>
                    <h3>A Supportive Partner and Advisor</h3>
                    <p>
                    Automation can be disruptive for many of our customers — fraud even more so. We are
                    committed to partnering with our customers on their journey to fully protect digital
                    financial systems.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </Section>

      <Section sectionClass="patternedGray"
        title="We Are "
        titleHighlight="Hiring"
      >
        <div className="mt-10 w-wrap w-wrapCards Career__cards">

          <div className="w1-3 wCard">
            <img src={icoImprove} alt="growth" />
            <h3 className="Career__card__title">Growth</h3>
            <p className="Career__card__text">
              Made up of business development, marketing, and sales, this team
              finds new prospects and markets for our growth.
            </p>
          </div>

          <div className="w1-3 wCard">
            <img src={icoID} alt="research and technology" />
            <h3 className="Career__card__title">Research and Technology</h3>
            <p className="Career__card__text">
              Led by PhD researchers in machine learning and creative engineers,
              this team builds out the core of our SaaS products.
            </p>
          </div>

          <div className="w1-3 wCard">
            <img src={icoJigsaw} alt="operations" />
            <h3 className="Career__card__title">Operations</h3>
            <p className="Career__card__text">
              All things budgeting, contracts, recruitment, and keeping our
              people healthy and focused are handled by this team.
            </p>
          </div>

        </div>
      </Section>
      <div className="Documents__demo" id="demo">
        <Section foregroundColor="white" backgroundColor="#2ea3e2">
        <div className="w-wrap">
          <div className="w1-2">
            <h2 className="mt-8">Join Us At <span className="white">Resistant AI</span></h2>
          </div>

          <div className="w1-2 vCenter">
            <Button
              variant="white"
              link="/contact" //subject=Resistant AI :: demonstration  request&body=Please contact me to arrange a mutually convenient time to meet."
              mailLink
              size="large"
            >
              Contact Us
            </Button>
          </div>
        </div>


        </Section>
      </div>
    </Layout>
  )
}

export default Career
